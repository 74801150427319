<div class=" bg-0B1F0D  pt-100 pb-70" >
    <div class="container">
        <div class="section">
          <br><br>
            <div class="battery-cea-home">
                <span style="color:#EFE6C0; opacity:0.3" >BYD <br> BATTERYBOX</span><br><br>
            </div> 
            
    <div class="row justify-content-center">
      <div class="row justify-content-center">
        <div class="col-lg-3 col-md-6">
            <div class="container-img container-img-resp-2 text-center">
                <img src="/assets/img/LV5-DOWNLOAD.png" style="padding-top:8em" alt="services-img"> 
            </div>
            <div class="services-content text-center">
                <h3 class="texto-blanco fuente-cool-light">LV5</h3>
                <ul>
                    <li class="mt-2">
                      <a href="/assets/pdf/BATTERYBOX DATASHEET LV5.0.pdf" target="_blank" class="text-crema">DATASHEET</a>
                    </li>
                    <li class="mt-2">
                      <a href="/assets/pdf/BYD Battery-Box LV5.0 QUICK START GUIDE.pdf" target="_blank" class="text-crema">QUICK START GUIDE</a>
                    </li>
                    <li class="mt-2">
                      <a href="/assets/pdf/BYD Battery-Box LV5.0 USER MANUAL.pdf" target="_blank" class="text-crema">USER MANUAL</a>
                    </li>
                    <li class="mt-2">
                      <a href="/assets/pdf/BYD Battery-Box LV5.0 LIMITED WARRANTY LETTER (1).pdf" target="_blank" class="text-crema">LIMITED WARRANTY</a>
                    </li>
                    <li class="mt-2">
                      <div class="accordion" id="accordionExample3">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingOne3">
                            <button class="accordion-button" style="padding: 0;" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne3" aria-expanded="false" aria-controls="collapseOne3">
                              Installation Video
                            </button>
                          </h2>
                          <div id="collapseOne3" class="accordion-collapse collapse" aria-labelledby="headingOne3" data-bs-parent="#accordionExample3">
                            <div class="accordion-body">
                              <iframe src="/assets/video/Installation Video LV5.mp4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
            </div> 
        </div><br>
        <div class="col-lg-3 col-md-6">
            <div class="container-img container-img-resp-2 text-center">
                <img src="assets/img/LVL-DOWNLOAD.png" style="padding-top:8em" alt="services-img"> 
            </div>
            <div class="services-content text-center">
                <h3 class="texto-blanco fuente-cool-light">BYD Premium LVL</h3>
                <ul>
                    <li class="mt-2">
                      <a href="/assets/pdf/BBOX_LVL_Datasheet_EN_V1.0_240626_L-668f948a29616.pdf" target="_blank" class="text-crema">DATASHEET</a>
                    </li>
                    <li class="mt-2">
                      <a href="assets/pdf/230922_BYD_Battery-Box_Premium_LVL_Minimum_Configuration_List_V2.3-650d5c7661417.pdf" target="_blank" class="text-crema">CONFIGURATION LIST</a>
                    </li >
                    <li class="mt-2">
                      <a href="/assets/pdf/BYD Battery-Box Premium Operating Manual LVL V1.2-600fb4fd1d7ca.pdf" target="_blank" class="text-crema">OPERATION MANUAL</a>
                    </li>
                    <li class="mt-2">
                      <a href="/assets/pdf/2022-01-11_BYD_Battery-Box_Premium_LVL_Service_Guideline_and_Checklist_EN_V1.2-620b633a4f403.pdf" target="_blank" class="text-crema">SERVICE GUIDELINE AND CHECKLIST</a>
                    </li>
                    <li class="mt-2">
                      <a href="/assets/pdf/BYD Battery-Box Premium LVL Limited Warranty-Residential-South Africa-EN V1.2-61a87d88f1dd5.pdf" target="_blank" class="text-crema">LIMITED WARRANTY SOUTH AFRICA</a>
                    </li>
                    <li class="mt-2">
                      <a href="/assets/pdf/BYD Battery-Box Premium LVL Limited Warranty-Residential-Middle East and Africa-EN V1.1-61a87d3ce9d6f.pdf" target="_blank" class="text-crema">LIMITED WARRANTY MIDDLE EAST AND AFRICA</a>
                    </li>
                    <li class="mt-2">
                      <div class="accordion" id="accordionExample1">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingOne1">
                            <button class="accordion-button" style="padding: 0;" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="false" aria-controls="collapseOne1">
                              Installation Video
                            </button>
                          </h2>
                          <div id="collapseOne1" class="accordion-collapse collapse" aria-labelledby="headingOne1" data-bs-parent="#accordionExample1">
                            <div class="accordion-body">
                              <iframe src="https://www.youtube.com/embed/BIU2FoOUxuo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
            </div>
        </div>
        <div class="col-lg-3 col-md-6">
            <div class="container-img container-img-resp text-center">
              <img src="assets/img/LV-FLEX-LITE-DOWNLOAD.png"  style="padding-top:8em" alt="services-img">
            </div>
            <div class="services-content text-center">
              <h3 class="texto-blanco fuente-cool-light">BYD LV Flex Lite</h3>
              <ul class="text-center">
                <li class="mt-2">
                  <a href="/assets/pdf/BBOX_LV FLEX LITE_Datasheet_EN_V1.0_240708_L-668f93922a6e4.pdf" target="_blank" class="text-crema">DATASHEET</a>
                </li>
                <li class="mt-2">
                  <a href="/assets/pdf/240413_BYD_Battery-Box_LV_Flex_Lite_Minimum_Configuration_List_V1.5-6625bc8acbcff.pdf" target="_blank" class="text-crema">CONFIGURATION LIST</a>
                </li >
                <li class="mt-2">
                  <a href="/assets/pdf/Battery-Box LV Flex Lite Operating Manual V1.0-61a03aa496a45.pdf" target="_blank" class="text-crema">OPERATION MANUAL</a>
                </li>
                <li class="mt-2">
                  <a href="/assets/pdf/2022-2-8 BYD Battery-Box LV FLEX LITE Service Guideline and Checklist-620b6dfeb5856.pdf" target="_blank" class="text-crema">SERVICE GUIDELINE AND CHECKLIST</a>
                </li>
                <li class="mt-2">
                  <a href="/assets/pdf/BYD Battery-Box LV Flex Lite Limited Warranty-Residential-South Africa-EN V1.1-619cb9348d4dc.pdf" target="_blank" class="text-crema">LIMITED WARRANTY SOUTH AFRICA</a>
                </li>
                <li class="mt-2">
                  <a href="/assets/pdf/BYD Battery-Box LV Flex Lite Limited Warranty-Residential-Middle East and Africa-EN V1.0-619cb8ffedf73.pdf" target="_blank" class="text-crema">LIMITED WARRANTY MIDDLE EAST AND AFRICA</a>
                </li>
              </ul>
            </div>
          </div>
          
        <div class="col-lg-3 col-md-6">
            <div class="container-img  text-center">
      <img src="/assets/img/HVS-DOWNLOAD.png" alt="services-img" style="padding-top:8em">
            </div>
            <div class="services-content text-center">
                <h3 class="texto-blanco fuente-cool-light">BYD HVS / HVM</h3>
                <ul>
                  <li class="mt-2">
                    <a href="/assets/pdf/BBOX_HVS_Datasheet_EN_V1.0_240626_L-668f92b10b0a6.pdf" target="_blank" class="text-crema">DATASHEET</a>
                  </li>
                  <li class="mt-2">
                    <a href="/assets/pdf/240419_BYD_Battery_Box_Premium_HVS&HVM_Compatible_Inverter_List_V2.16-6625d63f4bc3d.pdf" target="_blank" class="text-crema">COMPATIBILITY LIST</a>
                  </li>
                  <li class="mt-2">
                    <a href="/assets/pdf/230821_BYD_Battery-Box_Premium_HVS&HVM_Quick_Start_Guide_V2.7-64e2d6b5a0848.pdf" target="_blank" class="text-crema">QUICK START GUIDE</a>
                  </li>
                  <li class="mt-2">
                    <a href="/assets/pdf/231122_BYD_Battery-Box_Premium_HVS&HVM_Operating_Manual_V2.1_EN-655ea6ba188f6.pdf" target="_blank" class="text-crema">OPERATION MANUAL</a>
                  </li>
                  <li class="mt-2">
                    <a href="/assets/pdf/2022-01-11_BYD_Battery-Box_Premium_HVS_HVM_Service_Guideline_and_Checklist_EN_V1.5-620b5f836f34c.pdf" target="_blank" class="text-crema">SERVICE GUIDELINE AND CHECKLIST</a>
                  </li>
                  <li class="mt-2">
                    <a href="/assets/pdf/BYD Battery-Box Premium HVS&HVM Limited Warranty-South Africa-EN V1.1-61a87399f3c9e.pdf" target="_blank" class="text-crema">LIMITED WARRANTY SOUTH AFRICA</a>
                  </li>
                  <li class="mt-2">
                    <a href="/assets/pdf/BYD Battery-Box Premium HVS&HVM Limited Warranty-Middle East and Africa-EN V1.0-61a87347bc878.pdf" target="_blank" class="text-crema">LIMITED WARRANTY MIDDLE EAST AND AFRICA</a>
                  </li>
                  <li class="mt-2">
                    <div class="accordion" id="accordionExample2">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne2">
                          <button class="accordion-button" style="padding: 0;" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne2" aria-expanded="false" aria-controls="collapseOne2">
                            Installation Video
                          </button>
                        </h2>
                        <div id="collapseOne2" class="accordion-collapse collapse" aria-labelledby="headingOne2" data-bs-parent="#accordionExample2">
                          <div class="accordion-body">
                            <iframe src="https://www.youtube.com/embed/4_1Nk67ob5s" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  </ul>         
            </div>
        </div>
      </div><br>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
          <div class="container-img-resp-1 container-img-bmu text-center">
            <img src="assets/img/LV-BMU-DOWNLOAD.png" style="padding-top:4em" alt="services-img">
          </div>
          <div class="services-content text-center">
            <h3 class="texto-blanco fuente-cool-light">BYD LV BMU</h3>
            <ul class="texto-crema text-center">
              <li class="mt-2 ">
                <a href="/assets/pdf/240220_BYD Battery-Box Premium LV BMU Instruction Manual V1.2-65e181e151fcd.pdf" target="_blank" style="margin-right: 2em;" class="text-crema">INSTRUCTION MANUAL</a>
              </li>
            </ul>
          </div><br>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="container-img-bmu text-center container-img-resp">
            <img src="assets/img/PV-Panel-DWNLOAD.png"  alt="services-img">
          </div>
          <div class="services-content text-center">
            <h3 class="texto-blanco fuente-cool-light">BYD PV Panel</h3>
            <ul class="texto-crema text-center">
              <li class="mt-2">
                <a href="assets/pdf/MGK-36_-166-EN_(440W~455W).PDF" target="_blank" style="margin-right: 2em;" class="text-crema">OPERATION MANUAL</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="single-services">
            
          </div>
        </div>
      </div>
      
      
    </div>
</div>
</div>