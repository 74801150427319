<div class="main-banner-home">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="main-banner-text">
          <h1 class="mobile-font-size pc-font-size" style="margin-top:2.5em;">The Battery by BYD</h1>
          <p style="color:#EFE6C0;margin-top:0.7em; font-size:large;">Solar batteries for every application.</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="bg-fffdfd  pb-70" id="welcome-raion">
  <div class="container">
      <div class="section">
          <div class="parrafos">
              <div class="col-lg-6 welcome-title1">
                  <h1 ><span class="borders-bottom-green" >BYD</span> BatteryBox</h1>
              </div>
              <div class="col-lg-6 mt-3 fuente-pop-reg welcome-title2 ">
                  <p>BYD has developed a battery storage line, which is suitable for any application. While the modular LV and HV solutions fit any residential application, the commercial battery is suitable for big implementations.</p>
              </div>
          </div>
          <br>
          <div class="row justify-content-center">
            <h3 class="mt-5 mb-3">Low Voltage</h3>
              <div class="col-lg-4 col-md-6">
                  <div class="single-services-byd">
                      <div class="services-content ">
                          <h2>LV Flex Lite</h2>
                          <small class="borders-bottom-red">BYD BatteryBox 5 kWh to 320 kWh</small>
                      </div>
                      <div class="container-img text-center space-img" (mouseenter)="showText1()" (mouseleave)="hideText1()">
                          <img src="assets/img/LV-FLEX-LITE.png"  alt="services-img">
                          <span class="text">
                              <div class="services-content">
                                  <h2>LV Flex Lite</h2>
                                  <small class="borders-bottom-red">BYD BatteryBox 5 kWh to 320 kWh</small>
                              </div>
                              <ul class="fuente-pop-reg">
                                  <li>&bull; Lithium-Ion Battery (LFP)</li>
                                  <li>&bull; 5 kWh per module</li>
                                  <li>&bull; From 1 up to 64 modules in parallel</li>
                                  <li>&bull; (5 - 320kWh)</li>
                                  <li>&bull; Low Voltage Battery (48v)</li>
                                  <li>&bull; 10 year warranty</li>
                              </ul>
                                    <div class="d-flex justify-content-center">
                                      <a class="btn btn-byd" style="margin-top: 12em;font-size:small" href="/assets/pdf/BBOX_LV FLEX LITE_Datasheet_EN_V1.0_240708_L-668f93922a6e4.pdf" target="_blank" rel="noopener noreferrer">
                                          DOWNLOAD TECHNICAL DATA SHEET →
                                      </a>
                                  </div>
                          </span>
                      </div>
                  </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="single-services-byd">
                  <div class="services-content ">
                      <h2>Premium LVL</h2>
                      <small class="borders-bottom-red">BYD BatteryBox 15.4 to 983 kWh</small>
                    </div>
                  <div class="container-img text-center space-img" (mouseenter)="showText2()" (mouseleave)="hideText2()">
                    <img class="text-center" src="/assets/img/LV5-tower.png" alt="services-img">
                    <span class="text">
                      <div class="services-content ">
                          <h2>Premium LVL</h2>
                          <small class="borders-bottom-red">BYD BatteryBox 15.4 to 983 kWh</small>
                        </div>
                        <ul class="fuente-pop-reg">
                          <li>&bull; Lithium-Ion Battery (LFP)</li>
                          <li>&bull; 15.4 kWh per module</li>
                          <li>&bull; From 1 up to 64 modules in parallel</li>
                          <li>&bull; (15.4 - 983kWh)</li>
                          <li>&bull; Low Voltage Battery (48v)</li>
                          <li>&bull; 10 year warranty</li>
                      </ul>
                      <div class="d-flex justify-content-center">
                        <a class="btn btn-byd" style="margin-top: 12em;font-size:small" href="/assets/pdf/BBOX_LVL_Datasheet_EN_V1.0_240626_L-668f948a29616.pdf" target="_blank" rel="noopener noreferrer">
                            DOWNLOAD TECHNICAL DATA SHEET →
                        </a>
                    </div>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="single-services-byd">
                  <div class="services-content">
                      <h2>LV5.0</h2>
                      <small class="borders-bottom">BYD FinDreams 5 kWh to 160 kWh</small>
                    </div>
                  <div class="container-img text-center " (mouseenter)="showText3()" (mouseleave)="hideText3()">
                    <img src="/assets/img/LV5.0---SINGLE-UNIT.png"  alt="services-img">
                    <span class="text">
                      <div class="services-content">
                          <h2>LV5.0</h2>
                          <small class="borders-bottom">BYD FinDreams 5 kWh to 160 kWh</small>
                        </div>
                        <ul class="fuente-pop-reg">
                          <li>&bull; Lithium-Ion Battery (LFP)</li>
                          <li>&bull; 5.0 kWh per module</li>
                          <li>&bull; From 1 up to 32 modules in parallel (5 - 160 kWh)</li>
                          <li>&bull; low Voltage Battery (48v)</li>
                          <li>&bull; 7 year warranty</li>
                      </ul>
                      <div class="d-flex justify-content-center">
                        <a class="btn btn-byd" style="margin-top:11em; font-size:small" href="/assets/pdf/BATTERYBOX DATASHEET LV5.0.pdf" target="_blank" rel="noopener noreferrer">
                            DOWNLOAD TECHNICAL DATA SHEET →
                        </a>
                    </div>
                    </span>
                  </div>
                </div>
              </div>
              <h3 class="mt-5 mb-3">High Voltage</h3>
              <div class="col-lg-6 col-md-6">
                <div class="single-services-byd d-flex flex-column align-items-center justify-content-center">
                  <div class="services-content">
                      <h2>Premium HVS</h2>
                      <small class="borders-bottom">BYD BatteryBox 5.1 to 38.4 kWh</small>
                    </div>
                  <div class="text-center " (mouseenter)="showText3()" (mouseleave)="hideText3()">
                    <img src="/assets/img/HVS.png"  alt="services-img">
                    <span class="text">
                      <div class="services-content">
                          <h2>Premium HVS</h2>
                          <small class="borders-bottom">BYD BatteryBox 5.1 to 11.5 kWh</small>
                        </div>
                        <ul class="fuente-pop-reg">
                          <li>&bull; Lithium-Ion Battery (LFP)</li>
                          <li>&bull; HVS 2.56 kWh per module</li>
                          <li class="no-wrap">&bull; From 1 tower of 2-5 modules (5.1 to 12.8 kWh) up to 3 towers in parallel (38.4 kWh)</li>
                          <li>&bull; High Voltage Battery</li>
                          <li>&bull; 10 years warranty</li>
                      </ul>
                      <div class="d-flex justify-content-center">
                        <a class="btn btn-byd" style="margin-top:11em; font-size:small" href="/assets/img/HVM-fotor-2023091512450.webp">
                            DOWNLOAD TECHNICAL DATA SHEET →
                        </a>
                    </div>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="single-services-byd d-flex flex-column align-items-center justify-content-center">
                  <div class="services-content">
                      <h2>Premium HVM</h2>
                      <small class="borders-bottom">BYD BatteryBox 8.3 to 66.2 kWh</small>
                    </div>
                  <div class="text-center ml-5" (mouseenter)="showText3()" (mouseleave)="hideText3()">
                    <img src="assets/img/HVM.png" alt="services-img" >
                    <span class="text">
                      <div class="services-content">
                          <h2>Premium HVM</h2>
                          <small class="borders-bottom">BYD BatteryBox 5.1 to 11.5 kWh</small>
                        </div>
                        <ul class="fuente-pop-reg">
                          <li>&bull; Lithium-Ion Battery (LFP)</li>
                          <li>&bull; HVM 2.76 kWh per module up to 3 towers in parallel (66.2 kWh)</li>
                          <li>&bull;  From 1 tower of 3-8 modules (8.3 to 22.1 kWh)</li>
                          <li>&bull; High Voltage Battery</li>
                          <li>&bull; 10 years warranty</li>
                      </ul>
                      <div class="d-flex justify-content-center">
                        <a class="btn btn-byd" style="margin-top:11em; font-size:small" href="assets/pdf/230530_BYD_Battery-Box_Premium_HVS&HVM_Datasheet_V1.7_EN-647eedf90f9c3.pdf" target="_blank" rel="noopener noreferrer">
                            DOWNLOAD TECHNICAL DATA SHEET →
                        </a>
                    </div>
                    </span>
                  </div>
                </div>
              </div>
          </div>
      </div>
  </div>
</div>
<div class=" bg-fffdfd   pb-70" id="welcome-raion">
    <div class="container">
        <div class="section">
<div class="   " id="welcome-raion">
    <div class="section">
        <div class="battery mb-5">
            <span>Batterybox</span>
            <span>Advantages</span>
            </div>         
            <br>   
            <div class="row justify-content-center equal-height">
            <div class="col-lg-4 col-md-6">
                <div class="">
                    <div class="services-content">
                        <h3>Off-Grid <br> Applications</h3> <br>                           
                    </div>
                    <div class="services-img">
                        <img src="assets/img/OffGridApplications.jpg" alt="services-img">
                    </div>
                    <div class="services-content">
                        <small>Off-grid applications and emergency power capability pose no problem for the battery box. The high discharge capacity allows operation disconnected from the electricity grid. Whether your project supplies power to a remote cabin in the mountains or it is used as backup for sensitive loads, BYD has the right storage for you.</small>
                        
                    </div>
                </div>
            </div>
<div class="col-lg-4 col-md-6">
    <div class="">
        <div class="services-content">
            <h3>Self-Consumption <br> optimization</h3> <br>                            
        </div>
        <div class="services-img">
            <img src="assets/img/SelfConsuptionOptimization.jpg" alt="services-img">
        </div>
        <div class="services-content">
            <small>Renewable energy self consumption is the future. As around the world renewable generation subsidies decrease and electricity prices increase it becomes more economical to consume the energy you generate yourself.</small>
            
        </div>
    </div>
</div>
<div class="col-lg-4 col-md-6">
    <div class="">
        <div class="services-content">
            <h3>Industrial <br> Applications</h3> <br>                           
        </div>
        <div class="services-img">
            <img src="assets/img/IndustrialApplications.jpg" alt="services-img">
        </div>
        <div class="services-content">
            <small>From single family home to commercial applications. If you can design it, you can use the BatteryBox to build it. As an installer you want to work with the same common building block but adapt it to the needs of your clients. You can even start small and add capacity at any time of the life of the product.</small>
        </div>
    </div>
</div>
</div>
</div>
</div>
</div>
    </div>
</div>
<div class="pb-70 pt-70" style="background-color: #EFE6C0;">
    <div class="section">
<div class="battery" >
    <span>WHAT WE</span>
    <span>OFFER</span>
    </div>         
    <br><br> 
    <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="">
              <div class="services-img" style="vertical-align: top;">
                <img src="assets/img/sustainable.svg" height="250" alt="services-img">
              </div>
              <div class=""><br>
                <h2>Sustainable</h2><br>
                <small class="fuente-pop-reg">An important step for renewable energy decentralized and effective.</small>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class=""  >
              <div class="services-img" style="vertical-align: top;">
                <img src="assets/img/economical.svg" height="250px" alt="services-img">
              </div>
              <div class=""><br>
                <h2>Economical</h2><br>
                <small class="fuente-pop-reg">Save money and increase self-sufficiency by increasing self-consumption.</small>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="">
              <div class="services-img" style="vertical-align: top;">
                <img src="assets/img/safe.svg"  height="250px" alt="services-img">
              </div>
              <div class=""><br>
                <h2>Safe</h2><br>
                <small class="fuente-pop-reg">The robust lithium iron phosphate batteries meet the highest quality and safety standards.</small>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="">
              <div class="services-img" style="vertical-align: top;">
                <img src="assets/img/local.svg" height="250px" alt="services-img">
              </div>
              <div class=""><br>
                <h2>Local</h2><br>
                <small class="fuente-pop-reg">Enjoy support from our local team based in Nairobi.</small>
              </div>
            </div>
          </div>
        </div>
      </div>
</div>
</div>
<div class="pb-70 pt-70 " style="background-color: #ffffff; ">
    <div class="section  ptb-100">
<div class="battery" >
    <span style="opacity: 0.5;">AWARDS</span>
    </div>         
    <br><br> 
    <div style="text-align:center; width: 100%;">
        <small style="display: inline-block; width: 100%; max-width: 600px; text-align: center; line-height: 1.5; font-size: 0.7rem;" class="fuente-pop-reg">
          The BatteryBox meets the highest safety standards like VDE 2510-50 (HVS/HVM/LVS) and receives many awards and seals. In the independent Energy Storage Inspection of the university HTW Berlin, the Battery-Box is ranked as the battery with the highest efficiency on the market.
        </small>
      </div>
          <div class="container" >
    <div class="row col-10 mx-auto" >
        <div class="col-md-3">
            <div class="services-img ">
            <img src="assets/img/PV MAGAZINE.jpg" alt="services-img">
            </div>
        </div>
        <div class="col-md-3">
            <div class="services-img size-img-resp">
            <img src="assets/img/EUPD.jpg" height="250px" alt="services-img">
            </div>
        </div>
        <div class="col-md-3">
            <div class="services-img size-img-resp">
            <img src="assets/img/ENERGY STORAGE.jpg"  height="250px" alt="services-img">
            </div>
        </div>
        <div class="col-md-3">
            <div class="services-img size-img-resp">
            <img src="assets/img/TUV.jpg" height="250px" alt="services-img">
        </div>
        </div>
    </div>
    </div>
</div>
</div>
<div class="pt-100 pb-70" style="background-color: #0B1F0D;">
    <div class="container">
        <div class="section">
    <div class="parrafos">
        <div class="col-lg-6 welcome-title1">
            <h1 style="color: white; font-size:60px; text-align:start">Want to learn more <br> about our products?</h1>
            <div class="col-lg-12 col-md-12 " [class.active]="currentSection === 'Contact'">
              <a routerLink="/Contact" [queryParams]="{ section: 'Contact' }"><button type="submit" style="height: 40px; width:230px;" class="btn boton fuente-pop-reg">Contact Us →</button></a>
          </div>
    </div>
    </div>
</div>
</div>
</div>