<div class="main-banner-home pt-70">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="main-banner-text">
            <h1 class="mobile-font-size pc-font-size text-center">Powering Africa with Expertise</h1>
            <p style="color:#EFE6C0; font-size:large" class="mt-4">End-to-end services that elevate your energy solutions.</p><br>
            <div class="row justify-content-center">
            </div>
            <div id="mi-elemento"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="barra nav-about"  [ngClass]="{'nav-fixed': isNavFixed}">
      <p class="hover nav-letra">
        <a class="texto-negro letra-bold texto-chico" (click)="onClick('TECHNICAL')" [innerHTML]="'TECHNICAL SUPPORT'"></a>
      </p>
      <p class="hover nav-letra">
        <a class="texto-negro letra-bold texto-chico" (click)="onClick('TRAININGS')" [innerHTML]="'TRAININGS'"></a>
      </p>
      <p class="hover nav-letra">
        <a class="texto-negro letra-bold texto-chico" (click)="onClick('END')" [innerHTML]="'END OF LIFE'"></a>
      </p>
    </div>
    

  <div class="main-banner main-banner-imagen">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="card-cea" style="opacity: 0.94;">
            <div class="card-body" style="padding-top: 20px; padding-bottom: 20px;">
              <div class="row align-items-center ">
                <div class="col-lg-6 col-md-12">
                  <div class="about-content">
                    <div class="section-title">
                      <h1 style="text-align: left;">WHY OUR SERVICE MATTERS</h1><br>
                      <p class="fuente-pop-reg">At Collective Energy Africa (CEA), service is not just an add-on—it's the core of what sets us apart. We believe that delivering top-tier energy solutions is only the beginning. Our commitment to our customers extends beyond the sale, ensuring that every installation performs optimally and reliably. Our expert service, hands-on training, and dedicated support distinguish CEA as the leader among BYD distributors in Africa.</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 text-center">
                  <div class="about-video">
                    <img src="/assets/img/Training_1.jpg" alt="about">
                    <div class="video-btn">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pt-70 main-banner-byd"id="TECHNICAL">
  <div class="main-banner-byd " >
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="d-flex justify-content-center">
            <h1 style="text-align: left; font-size: 50px; color: #ffffff;">Comprehensive technical support</h1><br>
          </div>
          <div class="card-byd d-flex gap-3" style="opacity: 0.9;">
            <div class="card-body col-12" style= "background-color:#232e1f; ">

                <div class=" col-md-12 text-left ">
                  <h1 class="titulo text-left" >Service teams across Africa.</h1>
                  <p class="fuente-pop-reg mb-5" style="color:#efe6c0;">
                    Our dedicated service teams are strategically located in East and South Africa, providing full coverage across all African timezones. Whether you're in Nairobi or Cape Town, our expert technicians are just a phone call away, ready to assist with any issue. We pride ourselves on offering support tailored to your needs, with real people who understand your challenges.
                    For immediate assistance, reach out to our local technical support team via email or call us at:
                  </p>
                  <div class="box">
                    <a style="color:black; font-size:large;" href="mailto:service@collective-energy.africa">
                      service@collective-energy.africa
                    </a>
                  </div>
                  
                  <div class="box mt-2">
                    <p style="color:#000000; font-size:large" (click)="copyText('+254 207 640 823')">+254 207 640 823</p>
                  </div>
                </div>
                </div>

                
             
               
                    <div class="card-byd col-lg-6 col-md-12 text-left" style="background-color:#232e1f;">
                        <div class="card-body" style= "background-color:#232e1f; ">
                          <div class="row d-flex justify-content-center">
                            <div class=" col-md-12 text-left justify-content-center">
                              <h1 class="titulo text-left" >Rapid response & troubleshooting.</h1>
                              <p class="fuente-pop-reg mb-5" style="color:#efe6c0;">  When you need support, time is of the essence. Our rapid response services ensure that any technical issues are addressed swiftly and effectively. We’ve set up an online service center where you can log issues, track progress, and access resources in real-time. Visit our Service Centre to get started!.</p>
                              <img style="color:#efe6c0;" src="/assets/img/Screenshot-Service-Centre.png" alt="about">
                                <a class="borde-btn mt-5 btn-support"href="https://support.collective-energy.africa/" target="_blank">
                                  Online service center
                                </a>
                    </div>
              </div>

        </div>
      </div>
    </div>
  </div>
</div>
</div>
</div>
</div>
  <div class=" main-banner-byd">
  <div class="main-banner-byd ptb-100" >
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
       
          <div class="card-byd d-flex gap-3" style="opacity: 0.9;">
            <div class="card-body" style= "background-color:#232e1f; ">

                <div class=" col-md-12 text-left ">
                  <h1 class="titulo text-left" >Warehouses near you for fast product and spare part delivery.</h1>
                  <p class="fuente-pop-reg mb-5" style="color:#efe6c0;">
                    Our strategically located warehouses in East and South Africa are stocked with both products and spare parts, ensuring that what you need is always close by. Whether it's a new system or a replacement component, our proximity allows for rapid delivery, minimizing downtime and keeping your energy solutions running smoothly.
                    <br><br>
                  </p>
                  <div class="d-flex justify-content-center">

                  <img  src="/assets/img/Wharehouse.jpg" alt="about">
                    </div>
                </div>
                </div>
                    <div class="card-byd col-lg-6 col-md-12 text-left" style="background-color:#232e1f;">
                        <div class="card-body" style= "background-color:#232e1f; ">
                          <div class="row d-flex justify-content-center">
                            <div class=" col-md-12 text-left justify-content-center">
                              <h1 class="titulo text-left" >Hands-on expertise you can trust. <br><br></h1>
                              <p class="fuente-pop-reg mb-5" style="color:#efe6c0;">Our technical service team isn’t just knowledgeable—they’re seasoned professionals with extensive hands-on experience. From installations to troubleshooting, our experts have worked directly with the systems we support, ensuring that you receive top-tier service from those who know the technology inside and out. With CEA, your energy solutions are in capable, experienced hands.</p>  
                              <div class="d-flex justify-content-center">
                                <img  src="/assets/img/Expertise.jpg" alt="about">
                              </div>
                    </div>
              </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</div>
</div>
  <div class="pt-70 fondo-blanco"  id="TRAININGS">
  <div class="ptb-100" id="TRAININGS">
  <h1 style="text-align: center; font-size: 50px; color: #000000;">Local training programs.</h1><br>
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
            <div class="card-byd;" style="background-color: #0b1f0d;">
          <div style="color:#0b1f0d">
            <div class="card-body">
              <div class=" row align-items-center " style="padding: 10px;">
                <div class="col-lg-6 col-md-12">
                  <div class="about-content">
                    <div class="section-title">
                      <h1 class="texto-blanco">Empowering local talent.</h1><br>
                      <p class="fuente-pop-reg texto-blanco" >We don't just deliver products; we empower people. Through our extensive training programs, we equip local technicians with the knowledge and skills needed to install, maintain, and optimize BYD energy systems. Our training modules are designed to meet global standards while addressing local needs.</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 text-center">
                  <div class="about-video">
                    <img src="/assets/img/Training 2.jpg" alt="about">
                    <div class="video-btn">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    <div class="bg-fffdfd ">
        <div class="container">
            <div class="section">
                <div class="">
                    <div class="section">
                        <div class="mb-5">
                            <h1 style="text-align: center;  color: #000000;">Training labs across Africa.</h1>
                            <div>
                              <p class="texto-justidicado justify-content-center">To ensure accessible and high-quality training, CEA has established state-of-the-art training labs in key locations across Africa, including Kenya, Uganda, and South Africa. These facilities offer hands-on training with the latest BYD technology, ensuring that technicians are fully prepared to meet the challenges of modern energy storage solutions.</p><br>

                            </div>
                        </div>
                        <div class="row justify-content-center equal-height pb-100">
                            <div class="col-lg-3 col-md-6">
                              <div class="content-wrapper">
                                    <div class="services-content mb-2">
                                        <h3 class="borders-top">UGANDA –</h3>
                                        <span class="subtext">Makerere University <br></span><br>
                                    </div>
                                    <div class="services-img">
                                        <img src="/assets/img/Labs_Uganda.jpg" alt="services-img">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                              <div class="content-wrapper">
                                    <div class="services-content mb-2">
                                        <h3 class="borders-top">KENYA –</h3>
                                        <span class="subtext">Strathmore University <br></span><br>
                                    </div>
                                    <div class="services-img">
                                        <img src="/assets/img/Labs_Kenya.jpg" alt="services-img">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                              <div class="content-wrapper">
                                    <div class="services-content mb-2">
                                        <h3 class="borders-top">SOUTH AFRICA –</h3>
                                        <span class="subtext">South African Institute of Electrical Engineers <br></span><br>
                                    </div>
                                    <div class="services-img">
                                        <img src="/assets/img/Labs_South_Africa.jpg" alt="services-img">
                                    </div>
                                </div>
                            </div>  
                </div>
            </div>
        </div>
      </div>
      <div class="ptb-100" style="background-color: #ffffff;">
        <h1 style="text-align: center; font-size: 50px; color: #000000;">Local training programs.</h1><br>
          <div class="d-table">
            <div class="d-table-cell">
              <div class="container">
                  <div class="card-byd;" style="background-color: #0b1f0d;">
                <div style="color:#0b1f0d">
                  <div class="card-body" style="padding-top: 10px; padding-bottom: 10px;">
                    <div class=" row align-items-center " style="padding: 10px;">
                      <div class="col-lg-6 col-md-12">
                        <div class="about-content">
                          <div class="section-title">
                            <h1 class="texto-empowering">Upcoming trainings.</h1><br>
                            <p class="fuente-pop-reg texto-empowering" >We regularly host training sessions across Africa to make sure our partners and installers are up-to-date with the latest technology and best practices. Next is a list of our upcoming training sessions. Click on the links to register directly:</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-12 text-center">
                        <div class="about-video">
                          <img src="/assets/img/Upcoming-trainings.jpg" alt="about">
                          <div class="video-btn">
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div *ngIf="datosFiltrados" class="pt-5">
            <div class="row justify-content-center">
              <div *ngIf="isLoading" class="main-banner-white-home mt-5 d-flex justify-content-center align-items-center" style="background-color: #F8F8F8; height: 300px;">
                <h3 class="text-center"><fa-icon [icon]="faSpinner"  [spin]="true"></fa-icon>
                  {{ 'Loading' }}</h3>
              </div>
              <div *ngFor="let dato of datosFiltrados; let i = index" class="col-md-3 d-flex justify-content-center">
                <div class="card texto-chico letra-bold mt-3 mx-auto" style="width: 35vh;" rel="noopener noreferrer">
                  <ng-container *ngIf="dato.nombre || dato.pais || dato.ciudad">
                    <b *ngIf="dato.nombre" class="text-center mt-1" style="width: 100%;">{{ dato.nombre + ',' }}</b>
                    <b *ngIf="dato.pais && dato.nombre" class="text-center mt-1" style="width: 100%;">{{ ' ' + dato.pais + ',' }}</b>
                    <b *ngIf="dato.ciudad && (dato.nombre || dato.pais)" class="text-center mt-1" style="width: 100%;">{{ ' ' + dato.ciudad }}</b>
                    <p *ngIf="dato.fecha" class="text-center mt-1" style="width: 100%; font-weight: 1;">{{ dato.fecha }}</p><br>
                  </ng-container>
                  <div *ngIf="dato.url_evento" class="text-center" style="background-color: #232e1f; padding: 10px; width: 100%;">
                    <a href="{{ dato.url_evento }}" target="_blank" [innerHTML]="'REGISTER'" style="color: white"></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div *ngIf="datosFiltrados.length === 0 && !isLoading">
            <div class="row justify-content-center">
              <div class="card texto-chico letra-bold mt-3 mx-auto" style="width: 50%; height:auto" rel="noopener noreferrer">
                <p class="mt-3" [innerHTML]="'En este momento no hay formaciones disponibles. Consulte nuestra página con regularidad para estar al día'" style="color:red; font-weight:100"></p><br>
              </div>
            </div>
          </div>
          
          <div class="row justify-content-center">
            <div *ngFor="let dato of datos; let i = index" class="col-md-6 d-flex justify-content-center">
              <div class="card texto-chico letra-bold mt-3 mx-auto" style="width: 35vh;" rel="noopener noreferrer">
                <ng-container *ngIf="dato.nombre || dato.pais || dato.ciudad">
                  <b *ngIf="dato.nombre" class="text-center mt-1" style="width: 100%;">{{ dato.nombre + ',' }}</b>
                  <b *ngIf="dato.pais && dato.nombre" class="text-center mt-1" style="width: 100%;">{{ ' ' + dato.pais + ',' }}</b>
                  <b *ngIf="dato.ciudad && (dato.nombre || dato.pais)" class="text-center mt-1" style="width: 100%;">{{ ' ' + dato.ciudad }}</b>
                  <p *ngIf="dato.fecha" class="text-center mt-1" style="width: 100%; font-weight: 1;">{{ dato.fecha }}</p><br>
                </ng-container>
                <div  *ngIf="dato.url_evento" class="text-center" style="background-color: #232e1f; padding: 10px; width: 100%;">
                  <a href="{{ dato.url_evento }}" target="_blank" [innerHTML]="'REGISTER'" style="color: white"></a>
                </div>
              </div>
            </div>
          </div>
          </div >
         
          
          
          <div class="pt-70" id="END">
          <div class="pt-70" >
            <div class="d-table">
              <div class="d-table-cell">
                <div class="container">
                  <div class="card-cea" style="opacity: 0.94;">
                    <div class="card-body" style="padding-top: 20px; padding-bottom: 20px;">
                      <div class="row align-items-center ">
                        <div class="col-lg-6 col-md-12">
                          <div class="about-content">
                            <div class="section-title">
                              <h1 style="text-align: center; font-size: 45px">What comes after the producto end-of-life?</h1><br>
                              <p class="fuente-pop-reg"><span style="font-weight: 550;">THIS IS HOW IT WORKS</span><br>
                                Once a battery is no longer suitable for its initial usage, we either find a new purpose for it or recycle it’s components by feeding them into a circular economy. Either way, we always ensure a new start for our batteries.<br>
                                Battery Waste Processing rules are complex and country-dependent. However, in Collective Energy we are committed to providing you with the best possible solution regardless of the location of your installation.Therefore, if you have purchased one of our batteries through us or one of our partners, and you want to have them collected, please follow the steps below:  
                                <p class="fuente-pop-reg"><span style="font-weight: 550;">PROCESS</span><br><br>
                                    <p class="fuente-pop-reg"><span style="font-weight: 550;">1. Contact</span><br>
                                Please contact us at: takeback@collective-energy.africa and include the following pieces of information: 
                                All the serial numbers of your batteries 
                                Tell us if you need packaging 
                                The complete pickup address (Name, Street, ZIP, City, Country, Phone, Email) 
                                Very important: tell us the condition of the batteries and the reason you believe they have reached the end of their usable life.<br><br>
                                <p class="fuente-pop-reg"><span style="font-weight: 550;"> 2. Collect</span><br>
                                We will get back to you with your options to have them collected and processed in the most sustainable manner.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-12 text-center">
                          <div class="about-video">
                            <img src="/assets/img/LV5-TOWER-service.png" alt="about">
                            <div class="video-btn">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
  </div>
  <app-footer></app-footer>