<footer class="footer-area">
  <div class="container">
    <div class="flex">
      <div class="row">
        <div class="col-md-6 mb-5">
          <img src="/assets/img/CEA_black.svg" width="600" height="200" alt="Logo">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="row col-md-5 borders-top me-3">
        <div class="col-12">
          <p>Contact Us</p>
          <p class="mt-3 fuente-pop" style="color:#3A9B43;font-size:small">GENERAL INQUIERES</p>
          <p style="font-size:small" class="texto-negro">+254 712 988 211</p>
          <p style="font-size:small" class="texto-negro">+27 762 368 949</p>
          <p style="font-size:small" class="texto-negro">6th floor Morningside Office Park, Kilimani, Nairobi, Kenya</p>
          <p style="font-size:small" class="texto-negro">15 Ivor street, Gillview, Johannesburg, 2091, Collective Energy South Africa (Pty) Ltd</p>
          <p style="font-size:small" class="texto-negro">sales@collective-energy.africa</p>
          <p style="font-size:small" class="texto-negro">info@collective-energy.africa</p>
          <p style="font-size:small" class="texto-negro">contact@collective-energy.africa</p>
          <p class="fuente-pop" style="color:#3A9B43; font-size:small; margin-top: 2.4rem">TECHNICAL SUPPORT</p>
          <p style="font-size:small" class="texto-negro">+254 207 640 823</p>
          <p style="font-size:small" class="texto-negro">service@collective-energy.africa</p>
        </div>
      </div>

      <!-- Enlaces de navegación -->
      <div class="row col-md-3 borders-top">
        <div class="col-md-6">
          <p>Navigate</p>
          <p style="font-size:small" class="texto-negro"><a routerLink="/Home">Home</a></p>
<p style="font-size:small" class="texto-negro"><a routerLink="/about-us" fragment="section=about-us">About us</a></p>
<p style="font-size:small" class="texto-negro"><a routerLink="/byd-betterybox" fragment="section=byd-betterybox">Products</a></p>
<p style="font-size:small" class="texto-negro"><a routerLink="/solutions" fragment="section=solutions">C&I Solutions</a></p>
<p style="font-size:small" class="texto-negro"><a routerLink="/service" fragment="section=service">Service</a></p>
<p style="font-size:small" class="texto-negro"><a routerLink="/charging" fragment="section=charging">EV Charging</a></p>
<p style="font-size:small" class="texto-negro"><a routerLink="/Where-to-buy" fragment="section=Where-to-buy">Contact</a></p>

        </div>
        <div class="col-md-6">
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p style="font-size:small" class="texto-negro"><a href="https://support.collective-energy.africa/" target="_blank">Online service center</a></p>
          <p style="font-size:small" class="texto-negro"><a routerLink="/Downloads" fragment="section=Downloads">Downloads</a></p>
        </div>
      </div>

      <!-- Miembro de -->
      <div class="row col-md-4 borders-top flex">
        <div>
          <p>MEMBER OF:</p>
          <div>
            <img src="/assets/img/logoAFSIA.png" alt="Logo">
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>