<div class="main-banner-home pt-70">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="main-banner-text">
            <h1 class="mobile-font-size pc-font-size">CEA & BYD</h1>
            <p style="color:#EFE6C0; font-size:large">The energy for a better tomorrow</p><br>
            <div class="row justify-content-center">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main-banner main-banner-imagen">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="card-cea" style="opacity: 0.94;">
            <div class="card-body" style="padding-top: 20px; padding-bottom: 20px;">
              <div class="row align-items-center ">
                <div class="col-lg-6 col-md-12">
                  <div class="about-content">
                    <div class="section-title">
                      <h1 style="text-align: left; font-size: 50px">We are CEA</h1><br>
                      <p class="fuente-pop-reg">Collective Energy Africa (CEA) is your Pan-African smart energy expert, specializing in the distribution of BYD lithium-ion batteries across the continent. More than just a distributor, we offer comprehensive services—including training, advisory, follow-up, and technical support—to ensure that our products deliver the best performance. Our deep expertise in energy storage, coupled with our commitment to quality, guarantees that the solutions we provide are reliable, efficient, and tailored to the diverse needs of Africa. As East Africa’s largest supplier of lithium-ion batteries, we are expanding our reach and expertise across the continent—from East to West and beyond. Join us in powering a sustainable future for Africa.</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 text-center">
                  <div class="about-video">
                    <img src="/assets/img/CEA_black.svg" style="height: 21rem;" alt="about">
                    <div class="video-btn">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  

  <div class="main-banner-byd ptb-100">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="battery battery-cea">
            <span class="m-5" style="color:#FFFFFF">BYD PARTNERS</span>
          </div>
          <div class="card-byd" style="opacity: 0.9;">
            <div class="card-body" style="padding: 40px;">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-6 col-md-12">
                  <div class="about-content text-center">
                    <div class="section-title">
                      <div class="about-video">
                        <img src="assets/img/cealogos.png" alt="about">
                        <div class="video-btn">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 text-left">
                  <h1 class=""style="text-align: left; font-size: 50px;margin-left:0.8em;">Build Your Dreams</h1><br>
                  <p class="text-justify fuente-pop-reg" style="margin-left:3em; margin-right:3em">BYD (Bluid Your Dreams) is the world's largest manufacturer of solar batteries as well as a leading supplier of electric vehicles. Globally, BYD is one of the most successful, innovative, and fastest-growing companies. <br><br>
                    BYD was founded in February 1995 in Shenzhen, China, with only 20 workers. Today, BYD employs more than 220,000 people worldwide, including production and sales locations in Europe, America, Japan, India, and many more. <br><br>
                    BYD is the first large-scale enterprise involved in the entire energy chain - from generation to storage to consumption to achieve a sustainable energy industry.</p>
                  <div class="button-container">
                    <a href="https://www.bydbatterybox.com/" target="_blank" class="btn btn-three ms-5 fuente-pop-reg">LEARN MORE ABOUT BYD →</a>
                  </div>
                </div>
  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="bg-fffdfd ptb-100">
    <div class="container">
      <div class="section">
        <div class="">
          <div class="section">
            <div class="battery">
              <span>0 EMISSION</span>
              <span>ECOSYSTEM</span>
            </div>
            <div class="row justify-content-center equal-height">
              <div class=" small-padd">
                <small class="fuente-pop-reg economy-text">BYD is the world's largest manufacturer of electric vehicles. From electric forklifts to <br>
                  double decker buses in London or the new Skyrail - BYD electrifies all means of <br>
                  transport to create an emission-free ecosystem.
                </small>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="">
                  <div class="services-content">
                    <h3><span class="borders-top">Solar P</span>ower <br> Generation</h3> <br>
                  </div>
                  <div class="services-img">
                    <img src="assets/img/Solar.jpg" height="250"width="350" alt="services-img">
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="">
                    <div class="services-content">
                        <h3 ><span class="borders-top">Lighting</span>  Electrified <br> Transportation</h3> <br>                            
                    </div>
                    <div class="services-img">
                        <img src="assets/img/Lighting.jpg" height="250"width="350" alt="services-img">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="">
                    <div class="services-content">
                        <h3><span class="borders-top">Energy</span> <br> Storage</h3> <br>                           
                    </div>
                    <div class="services-img">
                        <img src="assets/img/EnergyStorage.jpg" height="250" width="350" alt="services-img">
                    </div>
                </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
